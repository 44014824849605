
export default {
  name: 'OrganismHighlightSpace',
  components: {},
  data() {
    return {
      isVisible: false,
      spaceData: {}
    }
  },
  computed: {
    engine3d() {
      return this.$engine3d || window.engine3d
    },
    clientManager() {
      return this.engine3d.default()
    },
    activeProjectSlug() {
      return this.$store.state.project.activeProject
    },
    currentProject() {
      return this.$store.state.base.landlordDetails?.projects.find((item) => {
        return item.slug === this.activeProjectSlug
      })
    },
    spaces() {
      return (
        this.$store.state.base.landlordDetails?.spaces?.filter((sp) => {
          return sp.project_id === this.currentProject.id
        }) || []
      )
    }
  },
  watch: {
    pinConfig: {
      deep: true,
      handler: function (newPinConfig) {
        this.pinsData = newPinConfig.pins
      }
    }
  },
  mounted() {
    this.clientManager.onUpdateHighlightValues = (highlightState) => {
      requestAnimationFrame(() => this.handleUpdatePopUpPosition(highlightState))
    }
  },
  methods: {
    handleUpdatePopUpPosition(highlightState) {
      const { x, y, space: spaceId, event } = highlightState
      if (!spaceId) return
      const space = this.getSpaceById(spaceId)
      switch (event) {
        case 'PointerOver':
          this.isVisible = true
          this.spaceData = space
          break
        case 'PointerOut':
        default:
          this.isVisible = false
          this.spaceData = {}
      }
      const componentRef = this.$refs.highlightPopUp
      if (componentRef) {
        componentRef.style.transform = `translateX(${x}px) translateY(${y}px)`
      }
    },
    getSpaceById(spaceId) {
      return this.spaces.find((s) => s.id === spaceId)
    }
  }
}
